import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import TextImage from "../components/sections/text-image"
import TextImageHero from "../components/sections/text-image-hero"
import References from "../components/sections/references"
import Cards from "../components/sections/cards"
import TextWithPictos from "../components/sections/text-with-pictos"
import Testimonials from "../components/sections/testimonials"
import SectionCTA from "../components/sections/section-cta"
import useModal from "../components/use-modal"
import CTAButton from "../components/elements/cta-button"
import Faq, { FaqP, FaqH } from "../components/elements/faq"
import Cities from "../components/sections/cities"
import ImagesModule from "../components/images"
import { useTranslation } from "gatsby-plugin-react-i18next"

const TeambuildingsPage = () => {

    const [toggle, ContactModal] = useModal()
    const { Picture } = ImagesModule()

    const { t } = useTranslation(['page_teambuildings', 'keywords', 'teambuildings'])

    const tMeta = t('meta')
    const tHero = t('hero')
    const tTextImage = t('text_image')
    const tImageText = t('image_text')
    const tReferences = t('references')
    const tProducts = t('products')
    const tKeywords = t('keywords:keywords')
    const tAdvantages = t('advantages')
    const tTestimonials = t('testimonials')
    const tLastCTA = t('last_cta')
    const tFaq = t('faq')
    const tFeaturedTeambuilding = t('featured_teambuilding')
    const tFortBayard = t('teambuildings:fort_bayard')

    return (
        <Layout>
            <Seo
                title={tMeta.title}
                description={tMeta.description}
            />
            <ContactModal />
            <TextImageHero
                picture="team_buildings"
                title={tHero.title}
                subtitle={tHero.subtitle}
                cta={tHero.cta}
                toggle={toggle}
                imageAlt={tHero.image_alt}
            />
            <TextImage
                alternate
                picture="teambuilding_inoubliable"
                title={tTextImage.title}
                imageAlt={tTextImage.image_alt}
            >
                <p className="mb-5">
                    {tTextImage.p1}
                </p>
                <p>
                    {tTextImage.p2}
                </p>
            </TextImage>
            <TextImage
                picture="teambuilding_experts"
                title={tImageText.title}
                imageAlt={tImageText.image_alt}
            >
                <p>
                    {tImageText.p1}
                </p>
            </TextImage>
            <References title={tReferences.title} />
            <Cards
                title={tProducts.title}
                cta={tProducts.cta}
                toggle={toggle}
                subtitle={
                    <>
                        <p className="mb-3">{tProducts.p1}</p>
                        <p className="mb-3 has-text-weight-bold">{tProducts.p2}</p>
                    </>
                }
                products={tProducts.products.map(product => {
                    product.content =
                        <>
                            <p className="mb-3">
                                {product.announce
                                    ?
                                    <>
                                        <span className="title is-uppercase is-inline-block is-4 has-text-centered has-background-primary has-text-white mb-3 py-1" style={{ width: '100%' }}>
                                            {product.announce}
                                        </span><br />
                                    </>
                                    : undefined
                                }
                                {product.text}
                            </p>
                            {
                                product.keywords && <ul className="mb-3">
                                    {
                                        product.keywords.map(kw =>
                                            <li key={kw}>
                                                <span className="has-text-weight-bold has-text-primary">#&nbsp;</span>
                                                {
                                                    tKeywords[kw]
                                                }
                                            </li>
                                        )
                                    }
                                </ul>
                            }
                            <p className="has-text-centered">
                                <CTAButton small className="is-outlined" onClick={toggle}>{tProducts.product_cta}</CTAButton>
                            </p>
                        </>
                    return product
                })}
            />
            <section className="section">
                <div className="container has-text-centered is-medium">
                    <div className="columns is-centered is-multiline">
                        <div className="column is-10">
                            <h2 className="title is-3 is-uppercase mb-4">
                                {tFeaturedTeambuilding.title}
                            </h2>
                        </div>
                        <div className="column is-10">
                            <div className="columns is-mobile">
                                <div className="column "><Picture name="fort-bayard-1" alt={tFortBayard.image_alt} /></div>
                                <div className="column "><Picture name="fort-bayard-2" alt={tFortBayard.thumbnails_alt[0]} /></div>
                                <div className="column "><Picture name="fort-bayard-12" alt={tFortBayard.thumbnails_alt[1]} /></div>
                                <div className="column "><Picture name="fort-bayard-4" alt={tFortBayard.thumbnails_alt[2]} /></div>
                            </div>
                            <p className="has-text-centered mb-5">
                                <CTAButton onClick={toggle}>{tFeaturedTeambuilding.cta}</CTAButton>
                            </p>
                            <div className="columns is-mobile">
                                <div className="column is-2"><Picture name="fort-bayard-5" alt={tFortBayard.thumbnails_alt[3]} /></div>
                                <div className="column is-2"><Picture name="fort-bayard-6" alt={tFortBayard.thumbnails_alt[4]} /></div>
                                <div className="column is-2"><Picture name="fort-bayard-13" alt={tFortBayard.thumbnails_alt[5]} /></div>
                                <div className="column is-2"><Picture name="fort-bayard-8" alt={tFortBayard.thumbnails_alt[6]} /></div>
                                <div className="column is-2"><Picture name="fort-bayard-9" alt={tFortBayard.thumbnails_alt[7]} /></div>
                                <div className="column is-2"><Picture name="fort-bayard-10" alt={tFortBayard.thumbnails_alt[0]} /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Cities />

            <TextWithPictos
                title={tAdvantages.title}
                pictos={["creations-originales", "depuis-2004", "partout-en-belgique", "en-24h", "devis-gratuit-sans-texte"]}
                pictosAlt={[tAdvantages.picto_1, tAdvantages.picto_2, tAdvantages.picto_3, tAdvantages.picto_4, tAdvantages.picto_5]}
            >
                <p className="mb-3">
                    {tAdvantages.p1}
                </p>
                <p className="mb-3">
                    {tAdvantages.p2}
                </p>
                <p className="mb-3">
                    {tAdvantages.p3}
                </p>
                <p className="mb-3">
                    {tAdvantages.p4}
                </p>
            </TextWithPictos>
            <Testimonials
                category="teambuilding"
                title={tTestimonials.title} />
            <SectionCTA
                picture="together"
                title={tLastCTA.title}
                toggle={toggle}
                imageAlt={tLastCTA.image_alt}
            >
                <p className="mb-5">
                    {tLastCTA.p1}
                </p>
                <p>
                    {tLastCTA.p2}
                </p>
            </SectionCTA>

            <section className="section">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-6">
                            <h2 className="title is-2">Faq</h2>
                            {
                                tFaq.faqs.map(
                                    ({ title, excerpt, content }) => (
                                        <Faq
                                            title={title}
                                            excerpt={excerpt}
                                        >
                                            {
                                                content.map(value => {
                                                    if (typeof value === 'string') {
                                                        return <FaqP>{value}</FaqP>
                                                    } else if (value.title) {
                                                        return <FaqH>{value.title}</FaqH>
                                                    }
                                                    return ""
                                                })
                                            }
                                        </Faq>
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
export default TeambuildingsPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;