import React from "react"
import ImagesModule from "../images"

export default ({title, picture, children, alternate = false, imageAlt}) => {
    const { Picture } = ImagesModule()

    return (
    <section className="section">
        <div className="container">
            <div className="columns is-centered" style={ {flexDirection: alternate &&  "row-reverse"}}>
                <div className="column is-4 is-10-mobile is-offset-1-mobile">

                    <Picture name={picture} alt={imageAlt} />
                </div>
                <div className="column is-5">
                <h2 className="title is-3 has-text-primary is-uppercase">{title}</h2>
                    {children}
                </div>
            </div>
        </div>
    </section>

    )
}